import React from "react";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/nextjs";
import { ToastContainer, toast } from "react-toastify";
import { ErrorContext, ThrowerFunc } from "./ErrorContext";
import { v4 } from "uuid";
import { StatusTypeEnum } from "components/shared/status";
import { ShowOnLocalhost } from "components/debug/show-on-localhost";
import { Alert } from "@mui/material";

type Props =
  | {
      type: "default";
      errorMessage: string;
      children?: React.ReactNode;
    }
  | {
      type: "in-page-message";
      errorMessage: React.ReactNode | string;
      children?: React.ReactNode;
    };

type State = {
  hasError: boolean;
  containerId: string;
};

/**
 * General-purpose error-capture component which can be used
 * several times in the same tree. Thanks to the ErrorContext.Provider
 * we can use the 'useErrorThrower' anywhere in the application
 * and it will activate the closest ErrorBoundary.
 */
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, containerId: v4() };
    this.subComponentErrorTrower = this.subComponentErrorTrower.bind(this);
    this.reportAndNotifyUser = this.reportAndNotifyUser.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * @param {*} error Error object
   * @param {*} errorInfo string representation of the React component tree
   */
  componentDidCatch(error, errorInfo) {
    this.reportAndNotifyUser({
      error,
      errorInfo,
      notification: {
        type: StatusTypeEnum.ERROR,
        message: this.props.errorMessage,
      } as Notification,
    });
  }

  subComponentErrorTrower(...args: Parameters<ThrowerFunc>) {
    const error = args[0];
    const {
      type = StatusTypeEnum.ERROR,
      message = this.props.errorMessage,
      extraSentryInfo,
    } = args[1] || {};
    this.reportAndNotifyUser({
      error,
      errorInfo: extraSentryInfo,
      notification: { type, message } as Notification,
    });
  }

  /**
   * Common error reporting function
   */
  reportAndNotifyUser({
    error,
    errorInfo = {},
    notification,
  }: {
    error: Error;
    errorInfo?: Record<string, string>;
    notification: Notification;
  }) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    if (this.props.type === "in-page-message") {
      return; // avoid displaying toast
    }
    // Wait a bit for <ToastContainer /> to be ready
    setTimeout(() => displayToastError(notification), 200);
  }

  render() {
    return (
      <>
        {/* Avoid showing too many errors at once */}
        <ToastContainer containerId="errorboundary" />
        <ErrorContext.Provider value={this.subComponentErrorTrower}>
          {/* If there is a hard-error in the three, avoid infinite re-render loop. */}
          {!this.state.hasError && this.props.children}
          {this.state.hasError &&
            this.props.type === "in-page-message" &&
            this.props.errorMessage}
        </ErrorContext.Provider>
      </>
    );
  }
}

/**
 * With error boundary is useful if one wants to ensure that a given
 * component has it's own error handling. Moreover, it's useful for
 * debugging as it will log out the component and the props that caused
 * it to fail.
 *
 * More about higher-order-components:
 * {@link https://maxrozen.com/implement-higher-order-component-react-typescript}
 */
export function withErrorBoundary<T>(Component: React.ComponentType<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => {
    return (
      <ErrorBoundary
        type="in-page-message"
        errorMessage={
          <>
            <Alert
              className="u-margin-bottom"
              severity="warning"
              sx={{ maxWidth: 600 }}
            >
              En feil oppstod og denne delen av nettsiden kunne ikke vises. Vi
              har logget feilen i våre systemer og beklager det inntruffne.
            </Alert>
            <ShowOnLocalhost>
              <h3>Debug data som kun vises ved lokal utvikling</h3>
              <p>
                Module name: <strong>{Component.displayName}</strong>
              </p>
              <p>Prop values:</p>
              <pre>{JSON.stringify(props, null, 2)}</pre>
            </ShowOnLocalhost>
          </>
        }
      >
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}

type Notification = {
  type: StatusTypeEnum[keyof StatusTypeEnum];
  message: string;
};

function displayToastError(notification: Notification) {
  const {
    message = "Noe gikk galt. Feilen har blitt logget i våre systemer.",
  } = notification;
  toast.warning(message, {
    containerId: "errorboundary",
    position: "top-center",
    autoClose: 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    toastId: message,
    progress: undefined,
    onClose: () => toast.clearWaitingQueue(),
  });
}
