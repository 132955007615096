import * as Sentry from "@sentry/nextjs";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

/**
 * Responsible for adding extra info about the user to the
 * Sentry configuration.
 */
export function AppAuthSentry({ children }) {
  const { data } = useSession();

  const { companyId, companyName, email, preferred_username } = data.user;

  useEffect(() => {
    if (!email) return;

    Sentry.setUser({
      id: preferred_username,
      email: email,
      extra: {
        companyId: companyId,
        companyName: companyName,
      },
    });
    Sentry.setExtra("companyId", companyId);
    Sentry.setExtra("companyName", companyName);
  }, [companyId, companyName, email, preferred_username]);

  return children;
}
