import { TokenParsedRenUser } from "./RenUserTokenParsed";

export interface RENUser {
  companyId?: string;
  companyName?: string;
  fullName?: string;
  email?: string;
  mainLocale?: string;
  locales?: string;
  authenticated?: boolean;
  username: string;
  roles: string[];
  hasRole: (role: string) => boolean;

  isSivKjetilOrBjarte: (user: RENUser) => boolean;

  /** * Access control bypass for users with role rensystemer.utvikler. */
  isDeveloper: (user: RENUser) => boolean;

  /**
   * Check if user is a super user, i.e bypasses most access control. Siv, Bjarte, developers, etc
   */
  isSuperUser: () => boolean;

  doesUserHavePermission: (
    permission: Permission,
    renblad?: Sanity.htmlRenblad
  ) => boolean;
  /**
   * @param level Accesslevel: read | edit | suggest | note | admin
   * @param renblad   Sanity.htmlRenblad
   * @param isKnownToDocApi Does this document give result in the doc api? (is it published in Alfresco)
   * @param isDocAccessible Does the user have access to the document
   * @returns True if the document exists and the user has access to it with the given level
   */
  hasRENbladAccess: (
    //TODO rename to hasDocumentAccess or something as it is for planbok and notes as well
    level: "read" | "edit" | "suggest" | "note" | "admin",
    renblad: Sanity.htmlRenblad,
    isKnownToDocApi: boolean,
    isDocAccessible: boolean | undefined
  ) => boolean;

  hasWorkingCopyAccess(document: Sanity.htmlRenblad): boolean;

  canPublishDocuments: (renUser, renblad) => boolean;

  /**
   * verifyAllowedRoles er koblet til allowedRoles i sanity schemaet, Sjekker om brukerens roller intersecter med de som er satt der.
   */
  verifyAllowedRoles: (allowedRoles: AllowedRoles, rsp?: any) => boolean;
}

/**
 * Access control bypass that allows Siv, Bjarte, Kjetil to edit all RENblad without beeing responsible or contributor
 */
const isSivKjetilOrBjarte = renUser => {
  return (
    renUser.email?.toLowerCase().trim() === "siv@ren.no" ||
    renUser.email?.toLowerCase().trim() === "kl@ren.no" ||
    renUser.email?.toLowerCase().trim() === "bjarte@ren.no"
  );
};

/**
 * Access control bypass for users with role rensystemer.utvikler.
 * Allows editing all RENblad and Planbok documents without being responsible or contributor
 */
const isDeveloper = renUser => {
  return renUser.hasRole("rensystemer.utvikler");
};

/**
 * Check if user is a super user, i.e bypasses most access control. Siv, Bjarte, developers, etc
 */
export function isSuperDuperUser(user: RENUser): boolean {
  return isSivKjetilOrBjarte(user) || isDeveloper(user);
}

const isPublishedVersion = renblad => {
  return renblad?.selectedVersion?._type === "htmlSnapshot";
};

const isWorkingVersion = renblad => {
  return renblad?.selectedVersion?._type === "htmlRenblad";
};

const isHearingVersion = renblad => {
  return isPublishedVersion(renblad);
};

const isResponsibleForRenblad = (renblad, user) => {
  return renblad?.responsible?.some(
    responsible =>
      responsible?.toLowerCase().trim() === user.email?.toLowerCase().trim()
  );
};

const isRenbladContributor = (renblad, renUser) => {
  return renblad?.contributors?.some(
    contributor =>
      contributor.email?.toLowerCase().trim() ===
      renUser.email?.toLowerCase().trim()
  );
};

const isRenbladContributorWithWritingAccess = (renblad, renUser) => {
  return renblad?.contributors?.some(
    contributor =>
      contributor.email?.toLowerCase().trim() ===
        renUser.email?.toLowerCase().trim() && contributor.contributor
  );
};

const isRenbladContributorWithSuggestionAccess = (renblad, renUser) => {
  return renblad?.contributors?.some(
    contributor =>
      contributor.email?.toLowerCase().trim() ===
      renUser.email?.toLowerCase().trim()
  );
};

const canReadRENblad = (
  user: RENUser,
  renblad: Sanity.htmlRenblad,
  isKnownToDocApi: boolean,
  isDocAccessible: boolean
): boolean => {
  if (isPublishedVersion(renblad) && isKnownToDocApi) {
    return isDocAccessible && user.hasRole("rensystemer");
  }

  if (isPublishedVersion(renblad) && !isKnownToDocApi) {
    return user.hasRole("rensystemer");
  }

  if (isWorkingVersion(renblad)) {
    if (isResponsibleForRenblad(renblad, user)) {
      return true;
    }

    if (isRenbladContributor(renblad, user)) {
      return true;
    }

    if (isDeveloper(user) || isSivKjetilOrBjarte(user)) {
      return true;
    }
  }

  return false;
};

const canEditRENblad = (user, renblad) => {
  if (isResponsibleForRenblad(renblad, user)) {
    return true;
  }

  if (isRenbladContributorWithWritingAccess(renblad, user)) {
    return true;
  }

  if (isDeveloper(user) || isSivKjetilOrBjarte(user)) {
    return true;
  }

  return false;
};

/**
 * Access control to check if user can publish RENblad and Planbok documents
 * @param RENUser
 * @param renblad
 * @returns Boolean indicating if user can publish documents
 */
const canPublishDocuments = (RENUser, renblad) => {
  if (isResponsibleForRenblad(renblad, RENUser)) {
    return true;
  }

  if (isDeveloper(RENUser) || isSivKjetilOrBjarte(RENUser)) {
    return true;
  }

  return false;
};

const canChangeRenbladSettings = (user, renblad) => {
  if (isResponsibleForRenblad(renblad, user)) {
    return true;
  }
  if (isDeveloper(user) || isSivKjetilOrBjarte(user)) {
    return true;
  }
  return false;
};

const canAddNoteToRENblad = (user, renblad) => {
  if (isPublishedVersion(renblad)) {
    return user.hasRole("rensystemer.brukeradministrator");
  }
  if (isHearingVersion(renblad)) {
    return user.hasRole("rensystemer");
    // TODO: Add access rules?
  }
  if (isWorkingVersion(renblad)) {
    if (isResponsibleForRenblad(renblad, user)) {
      return true;
    }

    if (isRenbladContributor(renblad, user)) {
      return true;
    }

    if (isDeveloper(user) || isSivKjetilOrBjarte(user)) {
      return true;
    }
  }

  return false;
};

const canAddSuggestionsToRENblad = (user, renblad) => {
  if (isResponsibleForRenblad(renblad, user)) {
    return true;
  }

  if (isRenbladContributorWithSuggestionAccess(renblad, user)) {
    return true;
  }

  if (isDeveloper(user) || isSivKjetilOrBjarte(user)) {
    return true;
  }

  return false;
};

export function hasRENbladAccess({
  user,
  level,
  renblad,
  isKnownToDocApi,
  isDocAccessible,
}: {
  user: RENUser;
  level: "read" | "edit" | "suggest" | "note" | "admin";
  renblad: Sanity.htmlRenblad;
  isKnownToDocApi: boolean;
  isDocAccessible: boolean | undefined;
}): boolean {
  switch (level) {
    case "read":
      return canReadRENblad(user, renblad, isKnownToDocApi, isDocAccessible);
    case "edit":
      return canEditRENblad(user, renblad);
    case "suggest":
      return canAddSuggestionsToRENblad(user, renblad);
    case "note":
      return canAddNoteToRENblad(user, renblad);
    case "admin":
      return canChangeRenbladSettings(user, renblad);
    default:
      return false;
  }

  return false;
}

export function hasWorkingCopyAccess({ user, document }): boolean {
  return canAddSuggestionsToRENblad(user, document);
}

// RENblad
const editor = ["rensystemer.kundeadministrator"];
// access is cumulative,
const hearingReviewer = [...editor];
const organizationAdmin = ["rensystemer.brukeradministrator"];
const contributor = [...editor];
const suggester = [...editor];
const viewer = ["renblad.bruker", "rensystemer.bruker", ...editor];

export const accessControlObject = {
  editor,
  hearingReviewer,
  organizationAdmin,
  contributor,
  suggester,
  viewer,
};

export type Permission = keyof typeof accessControlObject;

export function getRolesByCategory(category: Permission): string[] {
  const roleList = accessControlObject[category];

  return roleList || [];
}

function isAccessKey(level: Permission): level is Permission {
  return !!accessControlObject[level];
}
/**
  @param roles keycloak-roles of the user
  @param permission
*/

// TODO: Obsolete, remove when no longer in use
function doesUserHavePermission({
  roles,
  userEmail,
  permission,
  renblad,
}: {
  roles: string[];
  userEmail: string;
  permission: Permission;
  renblad?: Sanity.htmlRenblad;
}): boolean {
  // type-checking should prevent problems,
  // but this guards against undefined value on
  // accessControlObject anyway
  if (!isAccessKey(permission)) {
    return false;
  }
  const roleList = getRolesByCategory(permission);

  const isKeycloakRoleOnUser = roleList.reduce((acc, elem) => {
    if (roles.includes(elem)) {
      return true;
    }

    return acc;
  }, false);

  const responsible = renblad?.responsible || [];

  // do extra checking based on the permission asked about
  // and the renblad we're looking at
  // extensible for overiding based on permission asked for
  switch (permission) {
    case "editor":
    case "hearingReviewer":
      if (
        userEmail === "netlife@demo.ren.no" &&
        process.env.ENVIRONMENT !== "production"
      ) {
        return true;
      }
      return isKeycloakRoleOnUser;

    case "viewer":
      // override keycloak-role if user is responsible
      return (
        responsible.filter(user => user == userEmail).length !== 0 ||
        isKeycloakRoleOnUser
      );

    default:
      return isKeycloakRoleOnUser;
  }
}

function verifyAllowedRoles(userRoles: string[], allowedRoles: AllowedRoles) {
  const { roleArray = [] } = allowedRoles || {};
  // If there is no legitimate content in the roleArray or allowedRoles, then allow access.
  if (!roleArray || !roleArray.filter(e => e).length) {
    return true;
  }

  return userRoles.some(role => roleArray.includes(role));
}

export function createRenUser(userData = {} as any): RENUser {
  return {
    // Empty roles will be overriden by spread '...userData' if it
    // contains a roles property.
    roles: [],
    ...userData,
    authenticated: !!userData.username,
    hasRole(role: string): boolean {
      return this.roles.includes(role);
    },
    verifyAllowedRoles(allowedRoles: AllowedRoles) {
      return verifyAllowedRoles(this.roles, allowedRoles);
    },
    doesUserHavePermission(permission, renblad) {
      return doesUserHavePermission({
        roles: this.roles,
        permission,
        renblad,
        userEmail: this.email,
      });
    },
    hasRENbladAccess(level, renblad, isKnownToDocApi, isDocAccessible) {
      return hasRENbladAccess({
        user: this,
        level,
        renblad,
        isKnownToDocApi,
        isDocAccessible,
      });
    },
    hasWorkingCopyAccess(document: Sanity.htmlRenblad): boolean {
      return hasWorkingCopyAccess({ user: this, document });
    },
    isSuperUser() {
      return isSuperDuperUser(this);
    },
    // isDeveloper: () => isDeveloper(this),
  };
}

// Parse keycloak.tokenParsed into proper RenUser by renaming some fields.
export function createRenUserFromToken(
  tokenParsed: TokenParsedRenUser
): RENUser {
  const { roles = [] } = tokenParsed.realm_access;
  const { preferred_username: username, name: fullName, ...rest } = tokenParsed;
  return createRenUser({
    ...rest,
    username,
    fullName,
    roles: roles,
  });
}
